<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <!-- <h5>Ошибка</h5>
				<p>Такой страницы не существует.</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
